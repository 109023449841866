import { Button } from '@uikit';
import { ButtonGroup } from '@layouts';
import { useCopy } from '@app/utils';
import { GET_BANK_ACCOUNT, useFintechQuery } from '@app/data';

export interface GoalActionsProps {
  loading?: boolean;
  isLocked?: boolean;
  balance: number;
  percentage: number;
  editPercentage?: () => void;
  openTransfer: () => void;
}

/**
 * GoalActions
 *
 */
export const GoalActions = ({
  loading,
  isLocked,
  balance,
  percentage,
  editPercentage,
  openTransfer,
}: GoalActionsProps) => {
  const { c, $ } = useCopy('catch');
  const { data: bankAccountData, loading: bankAccountLoading } = useFintechQuery(GET_BANK_ACCOUNT, {
    fetchPolicy: 'cache-and-network',
  });

  const isBankingSetup = !!bankAccountData?.bankAccount;

  if (bankAccountLoading || (!bankAccountLoading && !isBankingSetup)) {
    return null;
  }

  return (
    <ButtonGroup loading={loading} textRight={$(balance)} textLeft={`${percentage}%`}>
      {editPercentage ? (
        <Button testID="edit-percentage" disabled={isLocked} full onPress={editPercentage} alt>
          {c('basics.edit')}
        </Button>
      ) : (
        <span />
      )}
      <Button testID="start-transfer" disabled={isLocked} full onPress={openTransfer}>
        {c('basics.transfer')}
      </Button>
    </ButtonGroup>
  );
};
